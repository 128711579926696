<template>
  <div class="" id="syllabus">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-8 offset-md-2 col-sm-10 offset-sm-1">
          <div class="table-responsive">
            <table class="table caption-top table-striped text-start">
              <caption><h4 class="bg-secondary text-white rounded p-1 ps-2">{{siteTitle}} Category wise syllabus</h4></caption>
              <thead>
              <tr>
                <th class="text-center" style="width: 50px">Sl</th>
                <th>Category</th>
                <th>Class</th>
                <th class="text-center">Syllabus</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(syllabus,key) in syllabuses" :key="key">
                <td class="text-center">{{key+1}}</td>
                <td>{{syllabus.category}}</td>
                <td>{{syllabus.classes}}</td>
                <td class="text-center" style="width: 150px">
                  <a target="_blank" :href="syllabus.file" class="btn btn-sm btn-primary me-2">বাংলা</a>
                  <a target="_blank" :href="syllabus.en_file" class="btn btn-sm btn-primary">English</a>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "Syllabus",
    data(){
      return {}
    },
    created(){},
    computed:{
      syllabuses(){return this.$store.getters.getSyllabus;},
      siteTitle(){return this.$store.getters.getSiteTitle;}
    },
    beforeMount() {}
  }
</script>

<style scoped>
  a.bf-2{
    font-size: 14.5px;
  }
</style>